import QueryString from "qs";
import {
  QueryFunctionOptions,
  getQueryObject,
  getNextPageParam,
} from "../shared";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";
import { StrapiOrder, StrapiResponse } from "~/shared-types";
import { useInfiniteQuery } from "@tanstack/react-query";

export const storeOrdersOptions = {
  populate: ["cartItems", "cartItems.product", "cartItems.product.images"],
  sortKey: "order",
  sortDirection: "asc" as const,
  publicationState: "preview" as const,
};

export const STORE_ORDERS_QUERY_KEY = "storeOrders";

export const getStoreOrders = async (options: QueryFunctionOptions = {}) => {
  const { strapiClient = defaultStrapiClient } = options;

  const optionsObject = {
    ...options,
    ...storeOrdersOptions,
  };
  const queryObject = getQueryObject(optionsObject);
  const query = QueryString.stringify(queryObject);

  const response = await strapiClient.get<StrapiResponse<StrapiOrder[]>>(
    `/store-orders?${query}`
  );
  return response.data;
};

export const useStoreOrdersQuery = (
  options: QueryFunctionOptions = {},
  initialData?: StrapiResponse<StrapiOrder[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  const orders = useInfiniteQuery({
    queryKey: [STORE_ORDERS_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) =>
      getStoreOrders({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });
  return orders;
};
