import axios from "axios";
import {
  DeliveryType,
  StoreCart,
  StrapiMainCategory,
  StrapiProduct,
  StrapiResponse,
  StrapiStore,
  StrapiStoreEntity,
  StrapiSubCategory,
  Address,
} from "~/shared-types";
import qs from "qs";
import { Cart } from "~/types";
import { getRoute } from "~/utils/getRoute";
import Stripe from "stripe";
import { getCookie } from "cookies-next";
import { GetServerSidePropsContext } from "next";
import https from "https";

export const populateParam = { populate: "*" }; // populate=* is a Strapi query parameter that populates media and relations one level down
export const populateQuery = qs.stringify(populateParam);

export const populateMainCategoryParam = {
  populate: ["images", "subCategories", "subCategories.mainCategory", "store"],
};
export const populateStoreParam = {
  populate: [
    "images",
    "productTags",
    "subCategories",
    "subCategories.mainCategory",
    "mainCategory",
    "store",
    "store.storeImage",
    "store.featuredMedia",
  ],
  filters: { isBlocked: { $ne: true } },
};
export const populateStoreQuery = qs.stringify(populateStoreParam);

const httpsAgent = process.env.NODE_ENV === 'development' ? new https.Agent({ rejectUnauthorized: false }) : undefined;
export const strapiClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/api`,
  httpsAgent
});

strapiClient.interceptors.request.use((config) => {
  const token = getCookie("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const getServerSideStrapiClient = (
  context: GetServerSidePropsContext
) => {
  const token = getCookie("token", context);

  return axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/api`,
    headers: { Authorization: `Bearer ${token}` },
    httpsAgent
  });
};

type Attributes<T extends { attributes: object }> = T["attributes"];

type Fields<T extends { attributes: object }> = Array<keyof Attributes<T>>;
type Populate<T extends { attributes: object }> = Partial<
  Record<keyof Attributes<T>, { fields: ["*"] | string[] }>
>;
export const defaultMediaFields = [
  "url",
  "name",
  "alternativeText",
  "width",
  "height",
  "formats",
  "mime",
];

interface QueryConfig<T extends { attributes: object }> {
  filters?: Record<string, unknown>;
  populate?: Populate<T>;
  limit?: number;
  sort?: string;
  start?: number;
  fields?: Fields<T>;
}

export async function findMainCategoryById(id: string | number) {
  const { data } = await strapiClient.get<
    StrapiResponse<StrapiMainCategory | null>
  >(`/main-categories/${id}?${populateQuery}`);
  return data.data;
}

export async function findSubCategoryById(id: string | number) {
  const { data } = await strapiClient.get<
    StrapiResponse<StrapiSubCategory | null>
  >(`/sub-categories/${id}?${populateQuery}`);
  return data.data;
}

export async function findSubCategoriesByMainCategory(
  { id }: { id: string | number },
  queryConfig?: QueryConfig<StrapiSubCategory>
) {
  const query = qs.stringify({
    ...queryConfig,
    filters: { mainCategory: { id: { $eq: id } } },
    populate: queryConfig?.populate ?? "*",
  });

  const { data } = await strapiClient.get<StrapiResponse<StrapiSubCategory[]>>(
    `/sub-categories?${query}`
  );
  return data.data;
}

export async function findRandomStores(
  limit = 15,
  queryConfig?: QueryConfig<StrapiStore>
) {
  const query = queryConfig ? qs.stringify(queryConfig) : "populate=*";
  const { data } = await strapiClient.get<StrapiResponse<StrapiStore[]>>(
    `/stores/random?${query}&limit=${limit}`
  );
  return data.data;
}

export async function findStoresWithLocation() {
  const query = qs.stringify({
    filters: {
      $and: [{ location: { $notNull: true } }, { acceptsPickUp: true }],
    },
    populate: ["storeImage", "mainCategories"],
  });
  const { data } = await strapiClient.get<StrapiResponse<StrapiStore[]>>(
    `/stores?${query}`
  );
  return data.data;
}

export async function findStoreById(
  id: string | number,
  queryConfig?: QueryConfig<StrapiStore>
) {
  const query = qs.stringify(queryConfig);
  const { data } = await strapiClient.get<StrapiResponse<StrapiStore | null>>(
    `/stores/${id}?${query}`
  );
  return data.data;
}

export async function findRandomProductsBySubCategory(
  {
    subCategoryId,
    limit = -1,
  }: { subCategoryId: string | number; limit?: number },
  fields?: Array<keyof StrapiProduct["attributes"]>
) {
  const query = qs.stringify({
    filters: {
      subCategories: { id: { $in: [subCategoryId] } },
      isBlocked: { $ne: true },
    },
    ...populateMainCategoryParam,
    fields,
  });
  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct[]>>(
    `/products/random?${query}&limit=${limit}`
  );
  return data.data;
}

export async function findProductById(id: string | undefined) {
  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct | null>>(
    `/products/${id}?${populateStoreQuery}`
  );
  return data.data;
}

export async function getCartProducts(cart: Cart) {
  const ids = cart.map(({ productId }) => productId);

  if (ids.length === 0) return [];

  const query = {
    populate: {
      store: {
        fields: [
          "name",
          "shownAddress",
          "deliveryPrice",
          "deliveryType",
          "acceptsPickUp",
          "freeDeliveryPurchasePrice",
          "offersFreeDelivery",
        ],
      },
      images: { fields: ["*"] },
    },
    fields: [
      "id",
      "name",
      "deliveryPrice",
      "price",
      "canOnlyBePickedUp",
      "isUnique",
      "hasOffer",
      "offerPrice",
      "offerStartDate",
      "offerEndDate",
      "offerTitle",
      "weightInGrams",
    ],
    filters: { id: { $in: ids }, isBlocked: { $ne: true } },
  };

  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct[]>>(
    `/products?${qs.stringify(query)}`
  );

  return data.data;
}

export async function findRandomProducts(
  storeId: StrapiStore["id"],
  limit = 12,
  fields?: Array<keyof StrapiProduct["attributes"]>
) {
  const query = qs.stringify({
    filters: {
      store: { id: { $eqi: storeId } },
      isBlocked: { $ne: true },
    },
    ...populateMainCategoryParam,
    fields,
  });
  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct[]>>(
    `/products/random?${query}&limit=${limit}`
  );
  return data.data;
}

export async function findSingleType<TReturn>(name: string) {
  const { data } = await strapiClient.get<StrapiResponse<TReturn>>(
    `/${name}?${populateQuery}`
  );
  return data.data;
}
// TODO: Implement queryConfig in more of the api endpoints
// This will make the calls more flexible
// because it allows the components to pass in their own query params
export const findStoresByMainCategories = async (
  mainCategoryIds: number[],
  queryConfig?: QueryConfig<StrapiStore>
) => {
  const query = qs.stringify({
    filters: {
      mainCategories: mainCategoryIds,
    },
    ...queryConfig,
  });

  const { data } = await strapiClient.get<StrapiResponse<StrapiStore[]>>(
    `/stores?${query}`
  );
  return data.data;
};

type PrepareCheckoutParams = {
  cart: StoreCart;
  deliveryType: DeliveryType;
  deliveryPrice?: number | null;
  receiverAddress?: Address;
  receiverName?: string;
  orderComment?: string;
  productCode?: string;
  servicePointId?: string;
};

export const prepareCheckout = async ({
  cart,
  deliveryType,
  deliveryPrice,
  receiverAddress,
  receiverName,
  orderComment,
  productCode,
  servicePointId,
}: PrepareCheckoutParams) => {
  const { data } = await strapiClient.post<{ checkoutUrl: string }>(
    "/orders/begin-checkout",
    {
      cart,
      successUrl: window.location.origin + getRoute("/cart/receipt").pathname,
      cancelUrl: window.location.href,
      deliveryType,
      deliveryPrice,
      receiverAddress,
      receiverName,
      orderComment,
      productCode,
      servicePointId,
    }
  );
  return data;
};

export const getReceipt = async (sessionId: string) => {
  const { data } = await strapiClient.get<{
    session: Stripe.Checkout.Session;
    items: Stripe.LineItem & { product?: Stripe.Product }[];
    store: StrapiStoreEntity;
  }>(`/orders/receipt/${sessionId}`);
  return data;
};
